/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var $hamburger  = $(".hamburger");
        var $mainNav    = $(".nav-primary");
        var $brand      = $(".brand");
        $hamburger.on("click", function(e) {
          $hamburger.toggleClass("is-active");
          // Do something else, like open/close menu
          $brand.toggleClass("show");
          $mainNav.toggleClass("show");
        });

        // lazy loading on images
        var bLazy = new Blazy({ 
          success: function(ele){
            // Image has loaded
            // Do your business here
            console.log("loaded.");
          }, 
          error: function(ele, msg){
            if(msg === 'missing'){
              // Data-src is missing
              console.log("missing.");
            }
            else if(msg === 'invalid'){
              // Data-src is invalid
              console.log("invalid.");
            }  
          }
        });

        // Smooth scrolling using jQuery easing
        $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
          if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname){
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $('html, body').animate({
                // scrollTop: (target.offset().top - 70)
                scrollTop: (target.offset().top)
              }, 1000, "easeInOutExpo");
              // $hamburger.toggleClass("is-active");
              return false;
            }
          }
        });

        // scroll to top
        var offset = 100;
        var duration = 500;
        var scrolltoHeight = '34px';
        var scrollShowing = false;

        function checkScrollPosition() {
          if ($(window).scrollTop() > offset) {
            if (!scrollShowing) {
              $('.back-to-top').animate({ height: scrolltoHeight, display: "inline-block" }, 150);
            }
            scrollShowing = true;
          } else {
            if (scrollShowing) {
              $('.back-to-top').animate({ height: '0', display: "none" }, 150);
              scrollShowing = false;
            }
          }
        }

        // $(window).scroll(function () {
        //   checkScrollPosition();
        // });

        $('.back-to-top').click(function (e) {
          e.preventDefault();
          $('html,body').animate({scrollTop: 0}, duration);
          return false;
        });
          
         // add class to navbar on scroll
        $(document).scroll(function () {
          checkScrollPosition();
          var $nav = $(".banner");
          $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
        });

        

        /*
        
        // parallax backgrounds
        var background_image_parallax = function($object, multiplier, posX, posY){
          multiplier = typeof multiplier !== 'undefined' ? multiplier : 0.5; // default speed
          multiplier = 1 - multiplier;
          posX = typeof posX !== 'undefined' ? posX : '0px'; // default x positioning
          posY = typeof posY !== 'undefined' ? posY : '0px'; // default x positioning
          var $doc = $(document);
          $object.css({"background-attatchment" : "fixed"});
          $(window).scroll(function(){
            var from_top = $doc.scrollTop(),
                bg_css = posX + " calc(" + posY + " + " +(multiplier * from_top) + "px)";
            $object.css({"background-position" : bg_css });
          });
        };

        //Just pass the jQuery object
        // background_image_parallax($(".banner-item"), 0.5, '50%', '50%');
        // background_image_parallax($("#banner-image"), 0.5, '50%', '50%');

        */

        var embedHeight = ( $(".logo-row").height() + $(".footer-supporters").height() + 48 );

        $(".twitter-embed").css("height", embedHeight);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_project': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('[data-fancybox="gallery"]').fancybox({
          // Options will go here
          infobar: false,
          buttons: [
            //"zoom",
            //"share",
            // "slideShow",
            //"fullScreen",
            //"download",
            // "thumbs",
            "close"
          ],
          protect: true,
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
